import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-advanced-features",
  "date": "2015-07-19",
  "title": "ES6 ADVANCED FEATURES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "We have learned about the new features ES6 brings us in ES6 New Features article. In this post, we will go through the advanced features of ES6 and how we can implement them."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Enhanced Object Literals`}</h2>
    <p>{`ES6 introduces new features for setting the prototype at contruction. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var foo = {
    // dunder proto
    __proto__: proto,
    // Shorthand for 'bar: bar'
    bar,
    // methods
    foobar() {
     // Super calls
     return 'foo' + super.bar();
    },
    // Computed (dynamic) property names
    [ "prop_" + (() => bar)() ]: bar
};
`}</code></pre>
    <h2>{`Template Strings`}</h2>
    <p>{`ES6 introduces template strings to improve the capabilities of JavaScript strings.`}</p>
    <Message type="info" title="DSLs" content="This features will enable developers to define strings with domain-specific languages (DSLs)." mdxType="Message" />
    <p>{`Template strings will enable:`}</p>
    <ul>
      <li parentName="ul">{`Multiline strings without hacking`}</li>
      <li parentName="ul">{`String tagging for safe HTML escaping`}</li>
      <li parentName="ul">{`Localization`}</li>
      <li parentName="ul">{`String formatting`}</li>
      <li parentName="ul">{`Embedded expressions`}</li>
      <li parentName="ul">{`String interpolation`}</li>
    </ul>
    <Message type="info" title="Syntax" content="Template Strings use back-ticks." mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// basic single line string
\`Hello "\\n" how are you?\`

// multiline strings
\`Hello
 how are you?.\`

// string interpolation
var name = 'Sally',
    time = 'today';

\`Hello \${name}, how are you \${time}?\`

// tagged templates
fn\`Hello \${name}! how are you \${today}!\`
`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/yoveya/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Comprehensions`}</h2>
    <p>{`Array comprehensions are similar to `}<inlineCode parentName="p">{`map`}</inlineCode>{` and `}<inlineCode parentName="p">{`filter`}</inlineCode>{`. `}</p>
    <Message type="info" title="Map & Filter" content="Best way to understand Array comprehensions is by comparing it with map and filter functions." mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[for (i of [1, 2, 3]) i * i]; // [1, 4, 9]
[1, 2, 3].map(function (i) { return i * i }); // [1, 4, 9]

[for (i of [1,4,2,3,-8]) if (i < 3) i]; // [1, 2, -8]
[1,4,2,3,-8].filter(function(i) { return i < 3 }); // [1, 2, -8]
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/wadapu/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <h2>{`Unicode`}</h2>
    <p>{`ECMAScript 6 introduces two new flags for regular expressions:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode>{` enables 'sticky' matching`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`u`}</inlineCode>{` enables Unicode-related fetaures`}</li>
    </ul>
    <p>{`Setting `}<inlineCode parentName="p">{`u`}</inlineCode>{` on a regular expression enables the use of `}<inlineCode parentName="p">{`Unicode code point escapes`}</inlineCode>{` (\\u{..}) in the pattern.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// \`a\` is U+0061 LATIN SMALL LETTER A, a BMP symbol.
/\\u{61}/u.test('a'); // true

// \`\` is U+1D306 TETRAGRAM FOR CENTRE, an astral symbol.
/\\u{1D306}/u.test(''); // true

// new RegExp behaviour, opt-in ‘u’
"".match(/./u)[0].length == 2 // true
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/mecore/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <h2>{`Module Loaders`}</h2>
    <p>{`ES6 provides you with module loades support:`}</p>
    <ul>
      <li parentName="ul">{`Dynamic loading`}</li>
      <li parentName="ul">{`State isolation`}</li>
      <li parentName="ul">{`Global namespace isolation`}</li>
      <li parentName="ul">{`Compilation hooks`}</li>
      <li parentName="ul">{`Nested virtualization`}</li>
    </ul>
    <p>{`With configurable default module loaders, new loaders can be constructed to evaluate and load code in `}<inlineCode parentName="p">{`isolated`}</inlineCode>{` or `}<inlineCode parentName="p">{`constrained`}</inlineCode>{` contexts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Dynamic loading – ‘System’ is default loader
System.import("lib/math").then(function(m) {
  alert("2π = " + m.sum(m.pi, m.pi));
});

// Create execution sandboxes – new Loaders
var loader = new Loader({
  global: fixup(window) // replace ‘console.log’
});
loader.eval("console.log(\\"hello world!\\");");

// Directly manipulate module cache
System.get("jquery");
System.set("jquery", Module({$: $})); // WARNING: not yet finalized
`}</code></pre>
    <h2>{`Map + Set + WeakMap + WeakSet`}</h2>
    <p>{`ES6 brings new data structures: `}<inlineCode parentName="p">{`Map`}</inlineCode>{`, `}<inlineCode parentName="p">{`WeakMap`}</inlineCode>{`, `}<inlineCode parentName="p">{`Set`}</inlineCode>{`, `}<inlineCode parentName="p">{`WeakSet`}</inlineCode>{`. `}</p>
    <p><strong parentName="p">{`Map`}</strong>{` - The Map data structure in ES6 lets you use arbitrary values as keys and is highly welcome.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let map = new Map();

map.set('foo', 100);
map.get('foo'); // 100

map.has('foo'); // true
map.delete('foo');
map.has('foo'); // false
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/savoze/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <p><strong parentName="p">{`WeakMap`}</strong>{` - A WeakMap is a map that doesn’t prevent its keys from being garbage-collected. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let wm = new WeakMap();
let foo = {};

wm.set(foo, 100);
wm.get(foo); // 100

wm.has(foo); // true
wm.delete(foo);
wm.has(foo); // false
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/yubabux/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <p><strong parentName="p">{`Set`}</strong>{` - A Set works for arbitrary values, is fast and handles `}<inlineCode parentName="p">{`NaN`}</inlineCode>{` correctly. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let set = new Set();
set.add('foo');

set.has('foo'); // true
set.delete('foo');
set.has('foo'); // false
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/hidewo/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <p><strong parentName="p">{`WeakSet`}</strong>{` - A WeakSet is a set that doesn’t prevent its elements from being garbage-collected. `}</p>
    <Message type="info" title="Garbage Collection" content="When using a `WeakMap` or `WeakSet` allows us to associate data with objects without having to worry about memory leaks." mdxType="Message" />
    <h2>{`Subclassable Built-ins`}</h2>
    <p>{`Creatng sub-constructors from built-ins is impossible. In ES6, built-ins like `}<inlineCode parentName="p">{`Array`}</inlineCode>{`, `}<inlineCode parentName="p">{`Date`}</inlineCode>{` and `}<inlineCode parentName="p">{`DOM Elements`}</inlineCode>{` can be subclassed.`}</p>
    <Message type="info" title="__proto__" content="Only works in current browsers with JavaScript engines that support `__proto__`." mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Foo(len) {
  var array = new Array(len);
  array.__proto__ = Foo.prototype;
  return array;
}

var foo = new Foo(5);
foo.length; // 5

Foo.prototype = Object.create(Array.prototype);

Foo.length; // 1
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/siqobo/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <h2>{`Binary and Octal Literals`}</h2>
    <p>{`In ES6, you can specifiy two new  numeric literals for integers in binary `}<inlineCode parentName="p">{`b`}</inlineCode>{` and octal `}<inlineCode parentName="p">{`0`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// binary
0b101; // 5

// octal
0o10; // 8
`}</code></pre>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/cerobu/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <h2>{`Reflect API`}</h2>
    <p>{`Reflection API exposese the runtime-level meta-operations on objects.`}</p>
    <p>{`Reflect API:`}</p>
    <ul>
      <li parentName="ul">{`Not a functional object.`}</li>
      <li parentName="ul">{`No `}<inlineCode parentName="li">{`construtor`}</inlineCode>{` available, so `}<inlineCode parentName="li">{`new`}</inlineCode>{` cannot be used with it.`}</li>
      <li parentName="ul">{`No `}<inlineCode parentName="li">{`call`}</inlineCode>{` or `}<inlineCode parentName="li">{`apply`}</inlineCode>{` internal methods available.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Foo = {a: 1};
Object.defineProperty(O, 'b', {value: 2});
Foo[Symbol('c')] = 3;

Reflect.ownKeys(Foo); // ['a', 'b', Symbol(c)]

function C(a, b){
  this.c = a + b;
}

var instance = Reflect.construct(C, [10, 12]);
console.log(instance.c); // 22
`}</code></pre>
    <h2>{`Tail Calls`}</h2>
    <p>{`A tail call happens when a function makes a function call as its `}<strong parentName="p">{`final action`}</strong>{`. At that point the function will do absolutely no more work: it passes the execution to whatever function is being called and disappears.This is guaranteed to not grow the stack unboundedly. Makes recursive algorithms like `}<inlineCode parentName="p">{`Fibonacci`}</inlineCode>{`safe in the face of unbounded inputs.`}</p>
    {
      /* <a class="jsbin-embed" href="http://jsbin.com/jeyivu/embed?js,console">JS Bin on jsbin.com</a> */
    }
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function factorial(n) {
    function recur(n, acc) {
        if (n === 0) {
            return acc;
        } else {
            return recur(n-1, n*acc);
        }
    }
    return recur(n, 1);
}

factorial(100000);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      